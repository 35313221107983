import {
    VueTyper
} from 'vue-typer'
import {
    ArrowRightIcon,
    ArrowUpIcon,
    ShieldIcon,
    CpuIcon,
    MailIcon,
    MonitorIcon,
    FeatherIcon,
    EyeIcon,
    UserCheckIcon,
    SmartphoneIcon,
    HeartIcon,
    HelpCircleIcon,
    UserIcon,
    PhoneIcon,
    MessageCircleIcon,
    VideoIcon,
    RadioIcon,
    PlayIcon,
    CheckIcon,
    GridIcon,
    LayersIcon,
    LayoutIcon
} from 'vue-feather-icons';
import {
    Carousel,
    Slide
} from 'vue-carousel';
import countTo from 'vue-count-to';
import Vue from "vue";
import router from "../../router";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import { contactInsert } from "@/services/contactService";

/**
 * Index-classic-saas component
 */
export default {
    data() {
        return {
            playerVars: {
                autoplay: 1
            },
            name: "",
            email: "",
            tel: "",
            message: "",
            modalContact: false,
            textResult: "",
            token: Vue.$cookies.get('user_token') || null,
            concurrent1: '< 50 ผู้ใช้',
            concurrent2: '< 100 ผู้ใช้',
            concurrent3: '< 250 ผู้ใช้',
            concurrent4: '< 500 ผู้ใช้',
            concurrent5: '< 1000 ผู้ใช้',
            concurrent6: '< 2500 ผู้ใช้',
            concurrent7: '< 5000 ผู้ใช้',
            concurrent8: '> 5000 ผู้ใช้',
        }
    },
    components: {
        'vue-typer': VueTyper,
        Navbar,
        Switcher,
        Footer,
        ArrowRightIcon,
        ArrowUpIcon,
        Carousel,
        Slide,
        countTo,
        ShieldIcon,
        CpuIcon,
        MonitorIcon,
        FeatherIcon,
        EyeIcon,
        UserCheckIcon,
        SmartphoneIcon,
        HeartIcon,
        HelpCircleIcon,
        UserIcon,
        PhoneIcon,
        MessageCircleIcon,
        MailIcon,
        VideoIcon,
        RadioIcon,
        PlayIcon,
        CheckIcon,
        GridIcon,
        LayersIcon,
        LayoutIcon

    },
    methods: {
        openNewSite(newSite) {
            window.open(newSite, "_blank");
        },
        goToLogin() {
            router.push({
                path: "/login",
            });
        },
        goToRegister() {
            router.push({
                path: "/register",
            });
        },
        actionContactInsert() {
            contactInsert({
                name: this.name,
                email: this.email,
                tel: this.tel,
                message: this.message,
            }).then((data) => {
                this.modalContact = true
                this.textResult = "ส่งข้อความสำเร็จ"
            })
                .catch((err) => {
                    this.modalContact = true
                    this.textResult = "ส่งข้อความไม่สำเร็จ"
                });
        },
    }
}
